import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { Store } from '@ngrx/store';
import { StoreService } from 'shared/store/store.service';
import { selectSelectedOrganizationId } from 'shared/store/user-info/user-info.selector';
import { Lang } from '../../core/transloco/transloco-root.module';
import { LoadingService } from '../../services/loading/service/loading.service';
import { SnackbarService } from 'services/snackbar/snackbar.service';
import { AppState, AuthService } from '@auth0/auth0-angular';
import { LoginService } from 'services/login.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  languages = [] as AvailableLangs;
  currentRoute = '';

  selectedOrganization$ = this.store.select(selectSelectedOrganizationId);

  constructor(
    private translationService: TranslocoService,
    private localeService: TranslocoLocaleService,
    protected loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private storeService: StoreService,
    private store: Store,
    private snackbarService: SnackbarService,
    private auth: AuthService,
    private loginService: LoginService,
  ) {}

  switchLanguage(value: Lang) {
    this.translationService.setActiveLang(value);
    this.localeService.setLocale(value);
    this.dateAdapter.setLocale(value);
  }

  ngOnInit(): void {
    this.languages = this.translationService.getAvailableLangs();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.highlightCurrentRoute();
      }
      if (event instanceof NavigationStart) {
        this.snackbarService.close();
      }
    });

    // TODO: Switch to current user setting
    this.switchLanguage(Lang.EN_GB);

    // refresh user role/org store data
    this.auth.isAuthenticated$.subscribe((value: boolean) => {
      if (value) {
        this.storeService.refreshOrganizationStore();
      }
    });

    // handle redirection after login
    this.auth.appState$.subscribe((state: AppState) => {
      this.loginService.refreshUserData();
      this.router.navigate([state.target]);
    });
  }
  highlightCurrentRoute() {
    this.currentRoute = this.router.routerState.snapshot.url;
    const navbarItems = document.querySelectorAll('.navbar-item');

    navbarItems.forEach((item) => {
      const itemRoute = item.getAttribute('routerLink');
      if (itemRoute && this.currentRoute.split('?')[0] === itemRoute) {
        const labelSpan = item.querySelector('.mdc-button__label > span');
        if (labelSpan) {
          labelSpan.classList.add('active');
        }
      } else {
        const labelSpan = item.querySelector('.mdc-button__label > span');
        if (labelSpan) {
          labelSpan.classList.remove('active');
        }
      }
    });
  }

  protected readonly Lang = Lang;
}
