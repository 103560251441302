import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRoutes } from 'core/enums/api.routes';
import { StoreService } from 'shared/store/store.service';
import { environment } from '../../environments/environment';
import { SnackbarService } from './snackbar/snackbar.service';
import { OperationStatus } from 'shared/components/operation-snackbar/operation-snackbar.component';

type Result = {
  affected: number;
};

@Injectable({ providedIn: 'root' })
export class LoginService {
  constructor(
    private http: HttpClient,
    private storeService: StoreService,
    private snackbarService: SnackbarService,
  ) {}

  TCMR_API = environment.apiUrl;

  refreshUserData() {
    return this.http
      .put<Result>(ApiRoutes.PUT_LOGIN, {})
      .subscribe((result: Result) => {
        if (result.affected) {
          this.storeService.refreshOrganizationStore();
        } else {
          this.snackbarService.open(
            'COMMON.NO_USER_ROLES_ERROR_MESSAGE',
            OperationStatus.ERROR,
          );
        }
      });
  }
}
