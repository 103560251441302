<ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
  <div *ngIf="auth.user$ | async as user" class="h-8">
    <button [matMenuTriggerFor]="menu">
      <img
        [src]="user.picture"
        alt=""
        class="h-8 hover:outline-4 hover:outline hover:outline-gray-500 rounded-full transition ease-in duration-1000"
      />
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logout()">
        {{ 'COMMON.LOGOUT_BUTTON_TEXT' | transloco }}
      </button>
    </mat-menu>
  </div>
</ng-container>

<ng-template #loggedOut>
  <button
    class="rounded-full bg-gray-700 hover:bg-gray-900 transition ease-in-out duration-300 w-9 h-9 flex items-center justify-center"
    (click)="login()"
  >
    <mat-icon>person</mat-icon>
  </button>
</ng-template>
