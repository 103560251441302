<!-- <div [class.error]="messageType === 1" [class.info]="messageType === 0">
  <div mat-dialog-content>
    <p>{{ data.message }}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="'close'">Close</button>
  </div>
</div> -->

<!-- Info -->
<div *ngIf="messageType === MessageType.INFO">
  <div mat-dialog-content>
    <div>
      <mat-icon class="done-icon">done_outline</mat-icon>
    </div>
    <div>
      {{ data.message }}
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="'close'">Close</button>
  </div>
</div>

<!-- Error -->
<div *ngIf="messageType === MessageType.ERROR">
  <div mat-dialog-content>
    <div>
      <mat-icon class="error-icon">error</mat-icon>
    </div>
    <div>
      {{ data.message }}
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="'close'">Close</button>
  </div>
</div>
