import { UserRoleInfo } from 'shared/models/UserRole';
import { Lang } from '../../../core/transloco/transloco-root.module';

export type UserInfoStore = {
  selectedUserRole?: UserRoleInfo;
  userRoles: UserRoleInfo[];
  userLanguage: Lang;
};
export const initialState: UserInfoStore = {
  selectedUserRole: undefined,
  userRoles: [],
  userLanguage: Lang.EN_GB,
};
