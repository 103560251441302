import { Component, Inject, signal } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
} from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';

export enum OperationStatus {
  SUCCESS,
  ERROR,
}

export type OperationInformation =
  | {
      status: OperationStatus.ERROR;
      message: string;
      reason: string;
      url?: string;
    }
  | {
      status: OperationStatus.SUCCESS;
      message: string;
      url?: string;
    };

@Component({
  selector: 'app-operation-snackbar',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatSnackBarLabel,
    MatSnackBarAction,
    MatSnackBarActions,
    MatButtonModule,
    TranslocoPipe,
  ],
  templateUrl: './operation-snackbar.component.html',
  styleUrl: './operation-snackbar.component.css',
})
export class OperationSnackbarComponent {
  operationInfo = signal({} as OperationInformation);
  protected readonly OperationStatus = OperationStatus;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: OperationInformation) {
    this.operationInfo.set(data);
  }

  isArray(variable: unknown): boolean {
    return Array.isArray(variable);
  }
}
