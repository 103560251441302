import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { UserRoleService } from 'services/api/user-role.service';
import {
  saveUserRoles,
  updateSelectedUserRoleByOrganizationId,
} from './user-info/user-info.actions';

@Injectable({ providedIn: 'root' })
export class StoreService {
  constructor(
    private userRoleService: UserRoleService,
    private store: Store,
    private authService: AuthService,
  ) {}

  refreshOrganizationStore() {
    this.userRoleService.getUserRoles().subscribe((userUserRoles) => {
      if (userUserRoles.length) {
        this.store.dispatch(
          saveUserRoles({
            userRoles: userUserRoles,
          }),
        );

        this.authService.user$.subscribe((user) => {
          const userMainOrg =
            user?.['https://mazdaeur.com/roles/user']?.['employerDomain']?.ref;

          const role =
            userUserRoles.find(
              (role) => role.organization?.code === userMainOrg,
            ) ?? userUserRoles[0];

          if (role) {
            this.store.dispatch(
              updateSelectedUserRoleByOrganizationId({
                organizationId: role.organizationId,
              }),
            );
          }

          // TODO: request user's rolePrivileges
          // TODO: create userRolePrivileges in store
        });
      }
    });
  }
}
