import { createAction, props } from '@ngrx/store';
import { Organization } from 'shared/models/Organization';
import { UserRoleInfo } from 'shared/models/UserRole';
import { Lang } from '../../../core/transloco/transloco-root.module';

export const saveSelectedUserRole = createAction(
  '[User Info] Save Selected User Role',
  props<{ userRole: UserRoleInfo }>(),
);

export const saveUserRoles = createAction(
  '[User Info] Save User Roles',
  props<{ userRoles: UserRoleInfo[] }>(),
);

export const updateSelectedUserRoleByOrganizationId = createAction(
  '[User Info] Update Selected User Role by Organization Id',
  props<{ organizationId: Organization['id'] }>(),
);

export const saveUserLanguage = createAction(
  '[User Info] Save User Language',
  props<{ language: Lang }>(),
);
