import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SelectedOrganizationInterceptor } from 'services/interceptors/selected-organization.interceptor';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { TranslocoRootModule } from './core/transloco/transloco-root.module';
import { MainComponent } from './layouts/main/main.component';
import { MainModule } from './layouts/main/main.module';
import { NotFoundModule } from './layouts/not-found/not-found.module';
import { LoadingInterceptor } from './services/loading/interceptor/loading-interceptor.service';
import { reducers } from './shared/store/state.reducers';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    MainModule,
    NotFoundModule,
    MatNativeDateModule,

    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0Audience,
        scope: 'openid profile email offline_access',
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.apiUrl}/*`,
            tokenOptions: {
              authorizationParams: {
                audience: environment.auth0Audience,
                scope: 'openid offline_access',
              },
            },
          },
        ],
      },
    }),

    StoreModule.forRoot(reducers, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      connectInZone: true,
      autoPause: true,
      logOnly: false,
      // logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SelectedOrganizationInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],
  bootstrap: [MainComponent],
})
export class AppModule {}
