import { ListOfValue } from './models/ListOfValue';

export default function mapLOV(lovValues: ListOfValue[]) {
  return lovValues.map((lov) => ({
    label: lov?.translatedValue,
    value: lov.id,
  }));
}

export function mapLovTable(lov: ListOfValue): string {
  return lov?.translatedValue as string;
}

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce(
    (groups, item) => {
      (groups[key(item)] ||= []).push(item);
      return groups;
    },
    {} as Record<K, T[]>,
  );
