import { environment } from '../../../environments/environment';
import { Organization } from '../../shared/models/Organization';
import { OrganizationParameter } from '../../shared/models/OrganizationParameter';
import { Bank } from '../../shared/models/Bank';
import { FinanceProduct } from '../../shared/models/FinanceProduct';
import { ResidualValueFile } from 'shared/models/ResidualValueFile';
import { FullServiceLeasingFile } from 'shared/models/FullServiceLeasingFile';
import { FullServiceLeasingValue } from 'shared/models/FullServiceLeasingValue';
import {
  InsuranceProdParam,
  InsuranceProduct,
  InsuranceProductTranslation,
} from '../../shared/models/InsuranceProduct';
import { FinanceProductMapping } from 'shared/models/FinanceProductMapping';
import { VehiclePrice } from 'shared/models/VehiclePrice';
import { FeatureParameter } from 'shared/models/FeatureParameters';
import { Product } from 'shared/models/Product';
import { DealerStock } from 'shared/models/DealerStock';
import { FinanceProductInsurance } from 'shared/models/FinanceProductInsurance';
import { Vehicle } from 'shared/models/Vehicle';
import { CalendarRule } from 'shared/models/CalendarRule';
import { ListOfValue } from '../../shared/models/ListOfValue';
import { ProductLine } from 'shared/models/ProductLine';
import { UsedValue } from 'shared/models/UsedValue';
import { BankContract } from 'shared/models/BankContract';
import { VehicleMapping } from 'shared/models/VehicleMapping';
import { VehicleMappingOutcome } from 'shared/models/VehicleMappingOutcome';
import { FileExport } from 'shared/models/FileExport';
import { DcsFieldConfiguration } from 'shared/models/DcsFieldConfiguration';
import { Campaign } from 'shared/models/Campaign';
import { ProductLineParameter } from 'shared/models/ProductLineParameter';
import { ActivityMatrix } from 'shared/models/ActivityMatrix';
import { ActivityMatrixRun } from 'shared/models/ActivityMatrixRun';
import { ActivityMatrixFilter } from 'shared/models/ActivityMatrixFilter';
import { Deal } from 'shared/models/Deal';
import { UsedValueMatrix } from 'shared/models/UsedValueMatrix';
import { LogicProcess } from 'shared/models/LogicProcess';
import { DealerParameterControl } from 'shared/models/DealerParameterControl';
import { DealerParameterAdjustment } from 'shared/models/DealerParameterAdjustment';
import { FileDto } from '../../shared/models/FileDto';
import { Activity } from 'shared/models/Activity';

const TCMR_API = environment.apiUrl;

export const ApiRoutes = Object.freeze({
  // App
  PUT_LOGIN: `${TCMR_API}/login`,

  // Organizations
  POST_FILTER_ORGANIZATIONS: `${TCMR_API}/organizations/filter`,
  POST_FILTER_ORGANIZATIONS_WITH_RELATIONS: `${TCMR_API}/organizations/filter-with-relations`,
  POST_CREATE_ORGANIZATIONS: `${TCMR_API}/organizations`,
  POST_GET_ORGANIZATION_VALUES: `${TCMR_API}/organizations/values`,
  DELETE_ORGANIZATIONS: (id: Organization['id']) =>
    `${TCMR_API}/organizations/${id}`,
  GET_ORGANIZATION: (id: Organization['id']) =>
    `${TCMR_API}/organizations/${id}`,
  GET_ORGANIZATION_TYPES: `${TCMR_API}/organization-type`,
  PATCH_EDIT_ORGANIZATION: (id: Organization['id']) =>
    `${TCMR_API}/organizations/${id}`,

  // Currencies
  GET_CURRENCIES: `${TCMR_API}/currencies`,
  POST_CREATE_CURRENCIES: `${TCMR_API}/currencies`,

  // Organization Parameters
  GET_ORGANIZATION_PARAMETERS: `${TCMR_API}/organizations/parameters`,
  POST_CREATE_ORGANIZATION_PARAMETER: `${TCMR_API}/organizations/parameters`,
  DELETE_ORGANIZATION_PARAMETERS: `${TCMR_API}/organizations/parameters`,
  PATCH_EDIT_ORGANIZATION_PARAMETER: (id: OrganizationParameter['id']) =>
    `${TCMR_API}/organizations/parameters/${id}`,
  DELETE_ORGANIZATION_PARAMETER: (id: OrganizationParameter['id']) =>
    `${TCMR_API}/organizations/parameters/${id}`,
  PUT_ORGANIZATION_PARAMETER_EXPORT: `${TCMR_API}/organizations/parameters/export`,

  // Dealers
  POST_FILTER_DEALERS_WITH_COUNTS: `${TCMR_API}/organizations/filter-with-contract-counts`,
  PATCH_EDIT_DEALER: (id: Organization['id']) =>
    `${TCMR_API}/organizations/${id}`,
  PUT_DEALERS_WITH_COUNTS_EXPORT: `${TCMR_API}/organizations/export`,

  // Banks
  POST_FILTER_BANKS: `${TCMR_API}/banks/filter`,
  POST_CREATE_BANK: `${TCMR_API}/banks`,
  GET_BANKS: `${TCMR_API}/banks/filter`,
  DELETE_BANK: (id: Bank['id']) => `${TCMR_API}/banks/${id}`,
  PATCH_UPDATE_BANK: (id: Bank['id']) => `${TCMR_API}/banks/${id}`,
  GET_BANK: (id: Bank['id']) => `${TCMR_API}/banks/${id}`,
  PUT_BANK_EXPORT: `${TCMR_API}/banks/export`,

  //Bank Contracts
  POST_FILTER_WITH_RELATIONS_BANK_CONTRACTS: `${TCMR_API}/bank-contract/filter-with-relations`,
  POST_BANK_CONTRACT: `${TCMR_API}/bank-contract`,
  DELETE_BANK_CONTRACT: (id: BankContract['id']) =>
    `${TCMR_API}/bank-contract/${id}`,
  PATCH_BANK_CONTRACT: (id: BankContract['id']) =>
    `${TCMR_API}/bank-contract/${id}`,
  PUT_BANK_CONTRACT_EXPORT: `${TCMR_API}/bank-contract/export`,

  // Finance Products
  POST_FILTER_FINANCE_PRODUCTS: `${TCMR_API}/finance-products/filter`,
  GET_FINANCE_PRODUCTS: `${TCMR_API}/finance-products/filter`,
  POST_CREATE_FINANCE_PRODUCTS: `${TCMR_API}/finance-products`,
  DELETE_FINANCE_PRODUCTS: (id: FinanceProduct['id']) =>
    `${TCMR_API}/finance-products/${id}`,
  PATCH_UPDATE_FINANCE_PRODUCTS: (id: FinanceProduct['id']) =>
    `${TCMR_API}/finance-products/${id}`,
  GET_FINANCE_PRODUCT: (id: FinanceProduct['id']) =>
    `${TCMR_API}/finance-products/${id}`,
  PUT_FINANCE_PRODUCT_EXPORT: `${TCMR_API}/finance-products/export`,

  // Finance Product Terms
  GET_FINANCE_PRODUCT_TERMS: (id: FinanceProduct['id']) =>
    `${TCMR_API}/finance-product/terms/${id}`,
  CREATE_FINANCE_PRODUCT_TERM: `${TCMR_API}/finance-product/terms`,
  PATCH_FINANCE_PRODUCT_TERMS: (id: FinanceProduct['id']) =>
    `${TCMR_API}/finance-product/terms/${id}`,
  DELETE_FINANCE_PRODUCT_TERMS: (id: FinanceProduct['id']) =>
    `${TCMR_API}/finance-product/terms/${id}`,
  PUT_FINANCE_PRODUCT_TERMS_EXPORT: `${TCMR_API}/finance-product/terms/export`,

  // Finance Product Translations
  GET_FINANCE_PRODUCT_TRANSLATIONS: (id: FinanceProduct['id']) =>
    `${TCMR_API}/finance-product/translations/${id}`,
  POST_CREATE_FIN_PROD_TRANSLATION: `${TCMR_API}/finance-product/translations`,
  PATCH_FINANCE_PRODUCT_TRANSLATION: (id: FinanceProduct['id']) =>
    `${TCMR_API}/finance-product/translations/${id}`,
  DELETE_FINANCE_PRODUCT_TRANSLATION: (id: FinanceProduct['id']) =>
    `${TCMR_API}/finance-product/translations/${id}`,
  PUT_FINANCE_PRODUCT_TRANSLATION_EXPORT: `${TCMR_API}/finance-product/translations/export`,

  // Finance Product Insurances
  GET_FINANCE_PRODUCT_INSURANCES: `${TCMR_API}/finance-product/insurances/filter`,
  POST_FINANCE_PRODUCT_INSURANCES: `${TCMR_API}/finance-product/insurances`,
  PATCH_FINANCE_PRODUCT_INSURANCES: (id: FinanceProductInsurance['id']) =>
    `${TCMR_API}/finance-product/insurances/${id}`,
  DELETE_FINANCE_FINANCE_PRODUCT_INSURANCES: (
    id: FinanceProductInsurance['id'],
  ) => `${TCMR_API}/finance-product/insurances/${id}`,
  PUT_FINANCE_PRODUCT_INSURANCES_EXPORT: `${TCMR_API}/finance-product/insurances/export`,

  // DCS Field Configuration
  POST_FILTER_DCS_FIELD_CONFIGURATION: `${TCMR_API}/dcs-field-config/filter`,
  POST_DCS_FIELD_CONFIGURATION: `${TCMR_API}/dcs-field-config`,
  PATCH_DCS_FIELD_CONFIGURATION: (id: DcsFieldConfiguration['id']) =>
    `${TCMR_API}/dcs-field-config/${id}`,
  DELETE_DCS_FIELD_CONFIGURATION: (id: DcsFieldConfiguration['id']) =>
    `${TCMR_API}/dcs-field-config/${id}`,
  PUT_DCS_FIELD_CONFIGURATION_EXPORT: `${TCMR_API}/dcs-field-config/export`,

  // Finance Product Mappings
  GET_FINANCE_PRODUCT_MAPPINGS: `${TCMR_API}/finance-product/mapping/filter`,
  GET_FINANCE_PRODUCT_MAPPINGS_WITH_RELATIONS: `${TCMR_API}/finance-product/mapping/filter-with-relations`,
  POST_CREATE_FINANCE_PRODUCT_MAPPINGS: `${TCMR_API}/finance-product/mapping`,
  PATCH_FINANCE_PRODUCT_MAPPINGS: (id: FinanceProductMapping['id']) =>
    `${TCMR_API}/finance-product/mapping/${id}`,
  DELETE_FINANCE_PRODUCT_MAPPINGS: (id: FinanceProductMapping['id']) =>
    `${TCMR_API}/finance-product/mapping/${id}`,
  PUT_FINANCE_PRODUCT_MAPPING_EXPORT: `${TCMR_API}/finance-product/mapping/export`,

  // Finance Product Mileage band
  GET_FINANCE_PRODUCT_MILEAGE_BAND: (id: FinanceProduct['id']) =>
    `${TCMR_API}/finance-product/mileage-band/${id}`,
  CREATE_FINANCE_PRODUCT_MILEAGE_BAND: `${TCMR_API}/finance-product/mileage-band`,
  PATCH_FINANCE_PRODUCT_MILEAGE_BAND: (id: FinanceProduct['id']) =>
    `${TCMR_API}/finance-product/mileage-band/${id}`,
  DELETE_FINANCE_PRODUCT_MILEAGE_BAND: (id: FinanceProduct['id']) =>
    `${TCMR_API}/finance-product/mileage-band/${id}`,
  PUT_FINANCE_PRODUCT_MILEAGE_BAND_EXPORT: `${TCMR_API}/finance-product/mileage-band/export`,

  // List Of Value
  POST_FILTER_LIST_OF_VALUES_VALUES: `${TCMR_API}/list-of-values/values`,
  POST_CREATE_LIST_OF_VALUE: `${TCMR_API}/list-of-values/`,
  PATCH_UPDATE_LIST_OF_VALUE: (id: ListOfValue['id']) =>
    `${TCMR_API}/list-of-values/${id}`,
  GET_LIST_OF_VALUE_TYPES: `${TCMR_API}/list-of-values/types`,
  POST_FILTER_LIST_OF_VALUE: `${TCMR_API}/list-of-values/filter`,
  DELETE_LIST_OF_VALUE: (id: ListOfValue['id']) =>
    `${TCMR_API}/list-of-values/${id}`,
  PUT_LIST_OF_VALUE_EXPORT: `${TCMR_API}/list-of-values/export`,

  // LOV Translations
  POST_FILTER_LOV_TRANSLATIONS: `${TCMR_API}/list-of-values/translations/filter`,
  POST_CREATE_LOV_TRANSLATIONS: `${TCMR_API}/list-of-values/translations`,
  PATCH_LOV_TRANSLATION: (id: ListOfValue['id']) =>
    `${TCMR_API}/list-of-values/translations/${id}`,
  DELETE_LOV_TRANSLATION: (id: ListOfValue['id']) =>
    `${TCMR_API}/list-of-values/translations/${id}`,
  PUT_LOV_TRANSLATIONS_EXPORT: `${TCMR_API}/list-of-values/translations/export`,

  //Residual Value Files
  GET_RESIDUAL_VALUE_FILES: `${TCMR_API}/residual-value-files/filter`,
  POST_RESIDUAL_VALUE_FILES: `${TCMR_API}/residual-value-files`,
  DELETE_RESIDUAL_VALUE_FILE: (id: ResidualValueFile['id']) =>
    `${TCMR_API}/residual-value-files/${id}`,
  PATCH_RESIDUAL_VALUE_FILE: (id: ResidualValueFile['id']) =>
    `${TCMR_API}/residual-value-files/${id}`,
  PUT_RESIDUAL_VALUE_FILE_EXPORT: `${TCMR_API}/residual-value-files/export`,

  //Residual Values
  POST_FILTER_RESIDUAL_VALUES_WITH_RELATIONS: `${TCMR_API}/residual-values/filter-with-relations`,
  POST_RESIDUAL_VALUES: `${TCMR_API}/residual-values`,
  DELETE_RESIDUAL_VALUE: (id: ResidualValueFile['id']) =>
    `${TCMR_API}/residual-values/${id}`,
  PATCH_RESIDUAL_VALUE: (id: ResidualValueFile['id']) =>
    `${TCMR_API}/residual-values/${id}`,
  PUT_RESIDUAL_VALUE_EXPORT: `${TCMR_API}/residual-values/export`,

  //Product lines
  POST_CREATE_PRODUCT_LINE: `${TCMR_API}/product-line/`,
  GET_PRODUCT_LINES: `${TCMR_API}/product-line/filter`,
  PUT_PRODUCT_LINE_EXPORT: `${TCMR_API}/product-line/export`,
  PATCH_UPDATE_PRODUCT_LINE: (id: ProductLine['id']) =>
    `${TCMR_API}/product-line/${id}`,
  DELETE_PRODUCT_LINE: (id: ProductLine['id']) =>
    `${TCMR_API}/product-line/${id}`,

  //Product line parameters
  POST_CREATE_PRODUCT_LINE_PARAMETER: `${TCMR_API}/product-line/parameters/`,
  POST_FILTER_PRODUCT_LINE_PARAMETERS: `${TCMR_API}/product-line/parameters/filter`,
  PATCH_UPDATE_PRODUCT_LINE_PARAMETER: (id: ProductLineParameter['id']) =>
    `${TCMR_API}/product-line/parameters/${id}`,
  DELETE_PRODUCT_LINE_PARAMETER: (id: ProductLineParameter['id']) =>
    `${TCMR_API}/product-line/parameters/${id}`,
  PUT_PRODUCT_LINE_PARAMETER_EXPORT: `${TCMR_API}/product-line/parameters/export`,

  //Full Service Leasing File
  GET_FULL_SERVICE_LEASING_FILES: `${TCMR_API}/full-service-leasing/files/filter`,
  POST_FULL_SERVICE_LEASING_FILES: `${TCMR_API}/full-service-leasing/files`,
  DELETE_FULL_SERVICE_LEASING_FILE: (id: FullServiceLeasingFile['id']) =>
    `${TCMR_API}/full-service-leasing/files/${id}`,
  PATCH_FULL_SERVICE_LEASINGE_FILE: (id: FullServiceLeasingFile['id']) =>
    `${TCMR_API}/full-service-leasing/files/${id}`,
  PUT_FULL_SERVICE_LEASINGE_FILE_EXPORT: `${TCMR_API}/full-service-leasing/files/export`,

  //Full Service Leasing Value
  GET_FULL_SERVICE_LEASING_VALUES: `${TCMR_API}/full-service-leasing/values/filter`,
  POST_FULL_SERVICE_LEASING_VALUES: `${TCMR_API}/full-service-leasing/values`,
  DELETE_FULL_SERVICE_LEASING_VALUE: (id: FullServiceLeasingValue['id']) =>
    `${TCMR_API}/full-service-leasing/values/${id}`,
  PATCH_FULL_SERVICE_LEASING_VALUE: (id: FullServiceLeasingValue['id']) =>
    `${TCMR_API}/full-service-leasing/values/${id}`,
  PUT_FULL_SERVICE_LEASING_VALUE_EXPORT: `${TCMR_API}/full-service-leasing/values/export`,

  // Insurance Product
  POST_FILTER_INSURANCE_PRODUCTS: `${TCMR_API}/insurance-product/filter`,
  POST_CREATE_INSURANCE_PRODUCTS: `${TCMR_API}/insurance-product`,
  DELETE_INSURANCE_PRODUCTS: (id: InsuranceProduct['id']) =>
    `${TCMR_API}/insurance-product/${id}`,
  PATCH_UPDATE_INSURANCE_PRODUCTS: (id: InsuranceProduct['id']) =>
    `${TCMR_API}/insurance-product/${id}`,
  GET_INSURANCE_PRODUCTS: (id: InsuranceProduct['id']) =>
    `${TCMR_API}/insurance-product/${id}`,
  PUT_INSURANCE_PRODUCTS_EXPORT: `${TCMR_API}/insurance-product/export`,

  // Insurance Product Translations
  POST_FILTER_INSURANCE_PRODUCT_TRANSLATIONS: `${TCMR_API}/insurance-products/translations/filter`,
  GET_INSURANCE_PRODUCT_TRANSLATIONS: (id: InsuranceProductTranslation['id']) =>
    `${TCMR_API}/insurance-products/translations/${id}`,
  POST_CREATE_INSURANCE_PROD_TRANSLATION: `${TCMR_API}/insurance-products/translations`,
  PATCH_INSURANCE_PRODUCT_TRANSLATION: (id: InsuranceProduct['id']) =>
    `${TCMR_API}/insurance-products/translations/${id}`,
  DELETE_INSURANCE_PRODUCT_TRANSLATION: (id: InsuranceProduct['id']) =>
    `${TCMR_API}/insurance-products/translations/${id}`,
  PUT_INSURANCE_PRODUCT_TRANSLATION_EXPORT: `${TCMR_API}/insurance-products/translations/export`,

  // Insurance Product Parameters
  POST_FILTER_INSURANCE_PRODUCT_PARAMS: `${TCMR_API}/insurance-products/parameters/filter`,
  GET_INSURANCE_PRODUCT_PARAMS: (id: InsuranceProdParam['id']) =>
    `${TCMR_API}/insurance-products/parameters/${id}`,
  POST_CREATE_INSURANCE_PROD_PARAMS: `${TCMR_API}/insurance-products/parameters`,
  PATCH_INSURANCE_PRODUCT_PARAMS: (id: InsuranceProdParam['id']) =>
    `${TCMR_API}/insurance-products/parameters/${id}`,
  DELETE_INSURANCE_PRODUCT_PARAMS: (id: InsuranceProdParam['id']) =>
    `${TCMR_API}/insurance-products/parameters/${id}`,
  PUT_INSURANCE_PRODUCT_PARAMS_EXPORT: `${TCMR_API}/insurance-products/parameters/export`,

  // Products
  GET_PRODUCTS: `${TCMR_API}/product/filter`,
  GET_PRODUCTS_WITH_RELATIONS: `${TCMR_API}/product/filter-with-relations`,
  POST_PRODUCTS: `${TCMR_API}/product`,
  DELETE_PRODUCT: (id: Product['id']) => `${TCMR_API}/product/${id}`,
  PATCH_PRODUCT: (id: Product['id']) => `${TCMR_API}/product/${id}`,
  PUT_PRODUCT_EXPORT: `${TCMR_API}/product/export`,

  // Vehicle Prices
  GET_VEHICLE_PRICES: `${TCMR_API}/vehicle-prices/filter`,
  GET_VEHICLE_PRICES_WITH_RELATIONS: `${TCMR_API}/vehicle-prices/filter-with-relations`,
  POST_VEHICLE_PRICES: `${TCMR_API}/vehicle-prices`,
  DELETE_VEHICLE_PRICE: (id: VehiclePrice['id']) =>
    `${TCMR_API}/vehicle-prices/${id}`,
  PATCH_VEHICLE_PRICES: (id: VehiclePrice['id']) =>
    `${TCMR_API}/vehicle-prices/${id}`,
  PUT_VEHICLE_PRICE_EXPORT: `${TCMR_API}/vehicle-prices/export`,

  // Feature Parameters
  POST_FILTER_FEATURE_PARAMETERS: `${TCMR_API}/feature-parameters/filter`,
  POST_CREATE_FEATURE_PARAMETER: `${TCMR_API}/feature-parameters/`,
  PATCH_UPDATE_FEATURE_PARAMETER: (id: FeatureParameter['id']) =>
    `${TCMR_API}/feature-parameters/${id}`,
  DELETE_FEATURE_PARAMETER: (id: FeatureParameter['id']) =>
    `${TCMR_API}/feature-parameters/${id}`,
  PUT_FEATURE_PARAMETER_EXPORT: `${TCMR_API}/feature-parameters/export`,

  // Dealer Stock
  POST_FILTER_DEALER_STOCK: `${TCMR_API}/dealer-stock/filter`,
  POST_CREATE_DEALER_STOCK: `${TCMR_API}/dealer-stock/`,
  PATCH_UPDATE_DEALER_STOCK: (id: DealerStock['id']) =>
    `${TCMR_API}/dealer-stock/${id}`,
  DELETE_DEALER_STOCK: (id: DealerStock['id']) =>
    `${TCMR_API}/dealer-stock/${id}`,
  PUT_DEALER_STOCK_EXPORT: `${TCMR_API}/dealer-stock/export`,

  // Dealer parameter control
  POST_FILTER_DEALER_PARAMETER_CONTROL: `${TCMR_API}/dealer-parameter-control/filter`,
  POST_CREATE_DEALER_PARAMETER_CONTROL: `${TCMR_API}/dealer-parameter-control`,
  PATCH_UPDATE_DEALER_PARAMETER_CONTROL: (id: DealerParameterControl['id']) =>
    `${TCMR_API}/dealer-parameter-control/${id}`,
  DELETE_DEALER_PARAMETER_CONTROL: (id: DealerParameterControl['id']) =>
    `${TCMR_API}/dealer-parameter-control/${id}`,
  PUT_DEALER_PARAMETER_CONTROL_EXPORT: `${TCMR_API}/dealer-parameter-control/export`,

  // Dealer parameter adjustment
  POST_FILTER_DEALER_PARAMETER_ADJUSTMENT: `${TCMR_API}/dealer-parameter-adjustment/filter`,
  POST_CREATE_DEALER_PARAMETER_ADJUSTMENT: `${TCMR_API}/dealer-parameter-adjustment`,
  PATCH_UPDATE_DEALER_PARAMETER_ADJUSTMENT: (
    id: DealerParameterAdjustment['id'],
  ) => `${TCMR_API}/dealer-parameter-adjustment/${id}`,
  DELETE_DEALER_PARAMETER_ADJUSTMENT: (id: DealerParameterAdjustment['id']) =>
    `${TCMR_API}/dealer-parameter-adjustment/${id}`,
  PUT_DEALER_PARAMETER_ADJUSTMENT_EXPORT: `${TCMR_API}/dealer-parameter-adjustment/export`,

  // Vehicle
  POST_FILTER_VEHICLE: `${TCMR_API}/vehicle/filter`,
  PATCH_UPDATE_VEHICLE: (id: Vehicle['id']) => `${TCMR_API}/vehicle/${id}`,
  PUT_VEHICLE_EXPORT: `${TCMR_API}/vehicle/export`,

  // Calendar Rules
  POST_FILTER_CALENDAR_RULE: `${TCMR_API}/calendar-rules/filter`,
  POST_FILTER_WITH_RELATIONS_CALENDAR_RULE: `${TCMR_API}/calendar-rules/filter-with-relations`,
  POST_CREATE_CALENDAR_RULE: `${TCMR_API}/calendar-rules/`,
  PATCH_UPDATE_CALENDAR_RULE: (id: CalendarRule['id']) =>
    `${TCMR_API}/calendar-rules/${id}`,
  GET_CALENDAR_RULE: (id: CalendarRule['id']) =>
    `${TCMR_API}/calendar-rules/${id}`,
  DELETE_CALENDAR_RULE: (id: CalendarRule['id']) =>
    `${TCMR_API}/calendar-rules/${id}`,
  PUT_CALENDAR_RULE_EXPORT: `${TCMR_API}/calendar-rules/export`,

  // Account
  POST_FILTER_ACCOUNTS: `${TCMR_API}/account/filter`,

  // Contact
  POST_FILTER_CONTACTS: `${TCMR_API}/contact/filter`,

  //Users
  POST_FILTER_USERS: `${TCMR_API}/user/filter`,
  PUT_USERS_EXPORT: `${TCMR_API}/user/export`,

  // User Role
  POST_FILTER_USER_ROLES: `${TCMR_API}/user-role/filter`,
  GET_USER_ROLES: `${TCMR_API}/user-role`,

  //Used Values
  POST_FILTER_USED_VALUES_WITH_RELATIONS: `${TCMR_API}/used-value/filter-with-relations`,
  PATCH_USED_VALUE: (id: UsedValue['id']) => `${TCMR_API}/used-value/${id}`,
  PUT_USED_VALUE_EXPORT: `${TCMR_API}/used-value/export`,

  //Used Value Matrix
  POST_FILTER_USED_VALUE_MATRIX_WITH_RELATIONS: `${TCMR_API}/used-value-matrix/filter-with-relations`,
  POST_CREATE_USED_VALUE_MATRIX: `${TCMR_API}/used-value-matrix`,
  PATCH_UPDATE_USED_VALUE_MATRIX: (id: UsedValueMatrix['id']) =>
    `${TCMR_API}/used-value-matrix/${id}`,
  DELETE_USED_VALUE_MATRIX: (id: UsedValueMatrix['id']) =>
    `${TCMR_API}/used-value-matrix/${id}`,
  PUT_USED_VALUE_MATRIX_EXPORT: `${TCMR_API}/used-value-matrix/export`,
  POST_USED_VALUE_MATRIX_RUN_VALIDATIONS: (id: UsedValueMatrix['id']) =>
    `${TCMR_API}/used-value-matrix/run-validations/${id}`,
  PUT_USED_VALUE_MATRIX_RUN_VALUATION: (id: UsedValueMatrix['id']) =>
    `${TCMR_API}/used-value-matrix/run-vehicle-valuation/${id}`,
  PUT_USED_VALUE_MATRIX_RUN_FORECAST: (id: UsedValueMatrix['id']) =>
    `${TCMR_API}/used-value-matrix/run-forecast/${id}`,

  // Vehicle Mapping
  POST_FILTER_VEHICLE_MAPPING: `${TCMR_API}/vehicle/mapping/filter`,
  POST_CREATE_VEHICLE_MAPPING: `${TCMR_API}/vehicle/mapping`,
  PATCH_UPDATE_VEHICLE_MAPPING: (id: VehicleMapping['id']) =>
    `${TCMR_API}/vehicle/mapping/${id}`,
  DELETE_VEHICLE_MAPPING: (id: VehicleMapping['id']) =>
    `${TCMR_API}/vehicle/mapping/${id}`,
  PUT_VEHICLE_MAPPING_EXPORT: `${TCMR_API}/vehicle/mapping/export`,
  PUT_VEHICLE_MAPPING_RUN: `${TCMR_API}/vehicle/mapping/run`,

  // Vehicle Mapping Outcome
  POST_FILTER_VEHICLE_MAPPING_OUTCOME: `${TCMR_API}/vehicle/mapping/outcome/filter`,
  PATCH_UPDATE_VEHICLE_MAPPING_OUTCOME: (id: VehicleMappingOutcome['id']) =>
    `${TCMR_API}/vehicle/mapping/outcome/${id}`,
  PUT_VEHICLE_MAPPING_OUTCOME_EXPORT: `${TCMR_API}/vehicle/mapping/outcome/export`,

  // File handler
  POST_FILTER_FILE_EXPORTS: `${TCMR_API}/file-export/filter`,
  GET_FILE_DOWNLOAD: (id: FileExport['id']) =>
    `${TCMR_API}/file-export/download/${id}`,
  PUT_FILE_EXPORTS_EXPORT: `${TCMR_API}/file-export/export`,

  // Campaigns
  POST_FILTER_CAMPAIGN: `${TCMR_API}/campaign/filter`,
  POST_FILTER_CAMPAIGN_WITH_RELATIONS: `${TCMR_API}/campaign/filter-with-relations`,
  POST_CREATE_CAMPAIGN: `${TCMR_API}/campaign`,
  PUT_CAMPAIGN_EXPORT: `${TCMR_API}/campaign/export`,
  POST_COPY_CAMPAIGN: `${TCMR_API}/campaign/copy`,
  PUT_APPROVE_CAMPAIGN: `${TCMR_API}/campaign/status`,
  GET_CAMPAIGN: (id: Campaign['id']) => `${TCMR_API}/campaign/${id}`,
  PATCH_UPDATE_CAMPAIGN: (id: Campaign['id']) => `${TCMR_API}/campaign/${id}`,
  DELETE_CAMPAIGN: (id: Campaign['id']) => `${TCMR_API}/campaign/${id}`,

  // Activity
  POST_FILTER_ACTIVITY: `${TCMR_API}/activity/filter`,
  POST_FILTER_ACTIVITY_WITH_RELATIONS: `${TCMR_API}/activity/filter-with-relations`,
  GET_ACTIVITY: (id: Activity['id']) => `${TCMR_API}/activity/${id}`,
  DELETE_ACTIVITY: (id: Activity['id']) => `${TCMR_API}/activity/${id}`,
  PUT_ACTIVITY_EXPORT: `${TCMR_API}/activity/export`,

  // Activity Matrix
  POST_FILTER_ACTIVITY_MATRIX: `${TCMR_API}/activity-matrix/filter`,
  POST_FILTER_ACTIVITY_MATRIX_WITH_RELATIONS: `${TCMR_API}/activity-matrix/filter-with-relations`,
  POST_CREATE_ACTIVITY_MATRIX: `${TCMR_API}/activity-matrix`,
  PATCH_UPDATE_ACTIVITY_MATRIX: (id: ActivityMatrix['id']) =>
    `${TCMR_API}/activity-matrix/${id}`,
  GET_ACTIVITY_MATRIX: (id: ActivityMatrix['id']) =>
    `${TCMR_API}/activity-matrix/${id}`,
  DELETE_ACTIVITY_MATRIX: (id: ActivityMatrix['id']) =>
    `${TCMR_API}/activity-matrix/${id}`,
  PUT_ACTIVITY_MATRIX_EXPORT: `${TCMR_API}/activity-matrix/export`,
  PUT_ACTIVITY_MATRIX_RUN: (id: ActivityMatrix['id']) =>
    `${TCMR_API}/activity-matrix/run/${id}`,

  // Activity Matrix Filter
  POST_FILTER_ACTIVITY_MATRIX_FILTER: `${TCMR_API}/activity-matrix-filter/filter`,
  POST_FILTER_ACTIVITY_MATRIX_FILTER_WITH_RELATIONS: `${TCMR_API}/activity-matrix-filter/filter-with-relations`,
  POST_CREATE_ACTIVITY_MATRIX_FILTER: `${TCMR_API}/activity-matrix-filter`,
  PATCH_UPDATE_ACTIVITY_MATRIX_FILTER: (id: ActivityMatrixFilter['id']) =>
    `${TCMR_API}/activity-matrix-filter/${id}`,
  GET_ACTIVITY_MATRIX_FILTER: (id: ActivityMatrixFilter['id']) =>
    `${TCMR_API}/activity-matrix-filter/${id}`,
  DELETE_ACTIVITY_MATRIX_FILTER: (id: ActivityMatrixFilter['id']) =>
    `${TCMR_API}/activity-matrix-filter/${id}`,
  PUT_ACTIVITY_MATRIX_FILTER_EXPORT: `${TCMR_API}/activity-matrix-filter/export`,
  GET_ACTIVITY_MATRIX_FILTER_RECORD_COUNT: (id: ActivityMatrixFilter['id']) =>
    `${TCMR_API}/activity-matrix-filter/record-count/${id}`,

  // Activity Matrix Run
  POST_FILTER_ACTIVITY_MATRIX_RUN: `${TCMR_API}/activity-matrix-run/filter`,
  POST_FILTER_ACTIVITY_MATRIX_RUN_WITH_RELATIONS: `${TCMR_API}/activity-matrix-run/filter-with-relations`,
  GET_ACTIVITY_MATRIX_RUN: (id: ActivityMatrixRun['id']) =>
    `${TCMR_API}/activity-matrix-run/${id}`,
  PUT_ACTIVITY_MATRIX_RUN_EXPORT: `${TCMR_API}/activity-matrix-run/export`,

  // Opportunity
  POST_FILTER_OPORTUNITY_WITH_RELATIONS: `${TCMR_API}/opportunity/filter-with-relations`,
  DELETE_OPORTUNITY: (id: Deal['id']) => `${TCMR_API}/opportunity/${id}`,
  PUT_OPORTUNITY_EXPORT: `${TCMR_API}/opportunity/export`,

  //Deal
  POST_FILTER_WITH_RELATIONS_DEAL: `${TCMR_API}/deal/filter-with-relations`,
  POST_DEAL: `${TCMR_API}/deal`,
  DELETE_DEAL: (id: Deal['id']) => `${TCMR_API}/deal/${id}`,
  PATCH_DEAL: (id: Deal['id']) => `${TCMR_API}/deal/${id}`,
  PUT_DEAL_EXPORT: `${TCMR_API}/deal/export`,

  //Dealer Multi Value
  POST_FILTER_WITH_RELATIONS_DEAL_MULTI_VALUE: `${TCMR_API}/deal-multi-value/filter`,
  POST_DEAL_MULTI_VALUE: `${TCMR_API}/deal-multi-value`,
  DELETE_DEAL_MULTI_VALUE: (id: Deal['id']) =>
    `${TCMR_API}/deal-multi-value/${id}`,
  PATCH_DEAL_MULTI_VALUE: (id: Deal['id']) =>
    `${TCMR_API}/deal-multi-value/${id}`,
  PUT_DEAL_MULTI_VALUE_EXPORT: `${TCMR_API}/deal-multi-value/export`,

  // Logic Process
  POST_FILTER_WITH_RELATIONS_LOGIC_PROCESS: `${TCMR_API}/logic-process/filter-with-relations`,
  POST_CREATE_LOGIC_PROCESS: `${TCMR_API}/logic-process`,
  PATCH_UPDATE_LOGIC_PROCESS: (id: LogicProcess['id']) =>
    `${TCMR_API}/logic-process/${id}`,
  DELETE_LOGIC_PROCESS: (id: LogicProcess['id']) =>
    `${TCMR_API}/logic-process/${id}`,
  PUT_LOGIC_PROCESS_EXPORT: `${TCMR_API}/logic-process/export`,

  // Logic Process Execution
  POST_FILTER_LOGIC_PROCESS_EXECUTION: `${TCMR_API}/logic-process-execution/filter`,
  PUT_LOGIC_PROCESS_EXECUTION_EXPORT: `${TCMR_API}/logic-process-execution/export`,

  // File Controller
  GET_DOWNLOAD_FILE: (fileId: FileDto['id']) =>
    `${TCMR_API}/files/download/${fileId}`,
});
