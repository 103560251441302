import { createReducer, on } from '@ngrx/store';
import { groupBy } from 'shared/mapFunctionLOV';
import { addLOVs } from './list-of-value.actions';
import { initialState } from './list-of-value.state';

export const lovReducer = createReducer(
  initialState,
  on(addLOVs, (state, { newLOVs }) => {
    const groupedLovs = groupBy(newLOVs, (lov) => lov.type);

    return {
      ...state,
      lovs: {
        ...state.lovs,
        ...groupedLovs,
      },
    };
  }),
);

export const lovReducers = {
  lov: lovReducer,
};
