import { UserRoleInfo } from 'shared/models/UserRole';

export type UserInfoStore = {
  selectedUserRole?: UserRoleInfo;
  userRoles: UserRoleInfo[];
};
export const initialState: UserInfoStore = {
  selectedUserRole: undefined,
  userRoles: [],
};
