import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Organization } from 'shared/models/Organization';
import { updateSelectedUserRoleByOrganizationId } from 'shared/store/user-info/user-info.actions';
import {
  selectSelectedOrganization,
  selectUserOrganizations,
} from 'shared/store/user-info/user-info.selector';

@Component({
  selector: 'app-organization-selector',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './organization-selector.component.html',
  styleUrl: './organization-selector.component.css',
})
export class OrganizationSelectorComponent {
  selectedOrganization$ = this.store.select(selectSelectedOrganization);
  userOrganizations$ = this.store.select(selectUserOrganizations);

  constructor(private store: Store) {}

  changeOrganization(orgId: Organization['id']) {
    this.store.dispatch(
      updateSelectedUserRoleByOrganizationId({ organizationId: orgId }),
    );
  }
}
