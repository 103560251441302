<mat-toolbar class="w-full">
  <mat-toolbar-row class="bg-black flex justify-between h-full">
    <div class="text-white h-full flex items-center text-sm">
      <button routerLink="/" class="text-white flex items-center mr-5">
        <a routerLink="/" mat-button>
          <img
            src="assets/mazda_logo.png"
            alt="Home"
            width="30"
            height="18.75"
          />
        </a>
        TCMR3
      </button>

      <a routerLink="/organizations/nsc" mat-button class="navbar-item text-xs">
        <span class="hover:text-gray-400 text-white">
          {{ 'NSC_PAGE.TITLE' | transloco }}
        </span>
      </a>
      <a
        routerLink="/vehicle-admin"
        mat-button
        class="navbar-item hover:text-gray-400 text-xs"
      >
        <span class="hover:text-gray-400 text-white">
          {{ 'VEHICLE_ADMIN_PAGE.TITLE' | transloco }}
        </span>
      </a>
      <a
        routerLink="/deal"
        mat-button
        class="navbar-item hover:text-gray-400 text-xs"
      >
        <span class="hover:text-gray-400 text-white">
          {{ 'DEAL_ADMIN_PAGE.TITLE' | transloco }}
        </span>
      </a>

      <a
        routerLink="/bank-admin"
        mat-button
        class="navbar-item hover:text-gray-400 text-xs"
      >
        <span class="hover:text-gray-400 text-white">
          {{ 'BANK_ADMIN_PAGE.TITLE' | transloco }}
        </span>
      </a>
      <a
        routerLink="/dealer-admin"
        mat-button
        class="navbar-item hover:text-gray-400 text-xs"
      >
        <span class="hover:text-gray-400 text-white">
          {{ 'DEALER_ADMIN_PAGE.TITLE' | transloco }}
        </span>
      </a>
      <a
        routerLink="/activity-admin"
        mat-button
        class="navbar-item hover:text-gray-400 text-xs"
      >
        <span class="hover:text-gray-400 text-white">
          {{ 'ACTIVITY_ADMIN_PAGE.TITLE' | transloco }}
        </span>
      </a>
    </div>

    <div class="flex gap-2 text-white items-center h-full">
      <app-organization-selector class="h-full items-center flex" />

      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        class="hover:rotate-90 transition ease-in hover:text-gray-400"
        [class]="currentRoute.startsWith('/settings') ? 'active-settings' : ''"
      >
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <a mat-menu-item routerLink="/settings/list-of-value">
          {{ 'NAVBAR.LIST_OF_VALUES' | transloco }}
        </a>
        <a mat-menu-item routerLink="/settings/users">
          {{ 'NAVBAR.USERS' | transloco }}
        </a>
        <a mat-menu-item routerLink="/settings/file-handler">
          {{ 'NAVBAR.FILES' | transloco }}
        </a>
        <a mat-menu-item routerLink="/settings/logic-process">
          {{ 'NAVBAR.LOGIC_PROCESS' | transloco }}
        </a>
      </mat-menu>

      <app-auth-button />
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div class="p-6" *ngIf="selectedOrganization$ | async as selectedOrg">
  <router-outlet></router-outlet>
</div>
