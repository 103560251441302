import { NgModule } from '@angular/core';
import { DefaultLayoutComponent } from './default-layout.component';
import { RouterOutlet } from '@angular/router';

@NgModule({
  declarations: [DefaultLayoutComponent],
  imports: [RouterOutlet],
  exports: [DefaultLayoutComponent],
})
export class DefaultModule {}
