import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  NEUTRAL = 'neutral',
  DANGER = 'danger',
}

@Component({
  selector: 'app-button-generator',
  templateUrl: './button-generator.component.html',
  styleUrls: ['./button-generator.component.css'],
})
export class ButtonGeneratorComponent {
  @Input() text!: string;
  @Input() icon!: string;
  @Input() plain!: boolean;
  @Input() variant: ButtonVariant = ButtonVariant.NEUTRAL;
  @Output() function = new EventEmitter<never>();
  @Input() disabled: boolean = false;

  onClick() {
    this.function.emit();
  }

  getText() {
    return this.text;
  }

  getClass() {
    if (this.plain && this.disabled) return 'text-gray-500 cursor-not-allowed';
    if (this.disabled) return ' bg-gray-300 text-gray-500 cursor-not-allowed';
    switch (this.variant) {
      case ButtonVariant.PRIMARY:
        return '-primary';
      case ButtonVariant.SECONDARY:
        return '-secondary';
      case ButtonVariant.NEUTRAL:
        return '-neutral';
      case ButtonVariant.DANGER:
        return '-danger';
    }
  }
}
