import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  OperationInformation,
  OperationSnackbarComponent,
  OperationStatus,
} from '../../shared/components/operation-snackbar/operation-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone,
  ) {}

  public open(
    message: string,
    action = OperationStatus.SUCCESS,
    reason?: string,
    duration = 5000,
  ) {
    this.zone.run(() => {
      this.snackBar.openFromComponent(OperationSnackbarComponent, {
        data: {
          message,
          reason,
          status: action,
        } as OperationInformation,
        duration,
      });
    });
  }

  public openWithUrl(
    message: string,
    url?: string,
    action = OperationStatus.SUCCESS,
    reason?: string,
    duration = 5000,
  ) {
    this.zone.run(() => {
      this.snackBar.openFromComponent(OperationSnackbarComponent, {
        data: {
          message,
          reason,
          status: action,
          url,
        } as OperationInformation,
        duration,
      });
    });
  }

  close() {
    this.snackBar.dismiss();
  }
}
