import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MainComponent } from './main.component';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageModalModule } from '../../shared/components/message-modal/message-modal.module';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AuthButtonComponent } from '../../features/auth-button/auth-button.component';
import { OrganizationSelectorComponent } from 'features/organization-selector/organization-selector.component';

@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    TranslocoPipe,
    MatToolbarModule,
    RouterOutlet,
    RouterLink,
    MatButtonModule,
    BrowserAnimationsModule,
    MessageModalModule,
    NgOptimizedImage,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatMenuModule,
    AuthButtonComponent,
    OrganizationSelectorComponent,
  ],
  exports: [MainComponent],
})
export class MainModule {}
