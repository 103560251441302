import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserInfoStore } from './user-info.state';

const selectUserInfoFeatureState =
  createFeatureSelector<UserInfoStore>('userInfo');

export const selectSelectedOrganization = createSelector(
  selectUserInfoFeatureState,
  (state: UserInfoStore) => state.selectedUserRole?.organization,
);

export const selectSelectedOrganizationId = createSelector(
  selectUserInfoFeatureState,
  (state: UserInfoStore) => state.selectedUserRole?.organization?.id,
);

export const selectSelectedRoleId = createSelector(
  selectUserInfoFeatureState,
  (state: UserInfoStore) => state.selectedUserRole?.role?.id,
);

export const selectUserOrganizations = createSelector(
  selectUserInfoFeatureState,
  (state: UserInfoStore) =>
    state.userRoles.map((userRole) => userRole.organization),
);

export const selectUserRoles = createSelector(
  selectUserInfoFeatureState,
  (state: UserInfoStore) => state.userRoles,
);
