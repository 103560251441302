import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { Store } from '@ngrx/store';
import { selectSelectedOrganizationId } from 'shared/store/user-info/user-info.selector';
import { Lang } from '../../core/transloco/transloco-root.module';
import { SnackbarService } from 'services/snackbar/snackbar.service';

@Component({
  selector: 'app-main',
  templateUrl: './main-app-layout.component.html',
  styleUrls: ['./main-app-layout.component.css'],
})
export class MainAppLayoutComponent implements OnInit {
  languages = [] as AvailableLangs;
  currentRoute = '';

  selectedOrganization$ = this.store.select(selectSelectedOrganizationId);

  constructor(
    private translationService: TranslocoService,
    private localeService: TranslocoLocaleService,
    public router: Router,
    public route: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private store: Store,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.languages = this.translationService.getAvailableLangs();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.highlightCurrentRoute();
      }
      if (event instanceof NavigationStart) {
        this.snackbarService.close();
      }
    });
  }

  highlightCurrentRoute() {
    this.currentRoute = this.router.routerState.snapshot.url;
    const navbarItems = document.querySelectorAll('.navbar-item');

    navbarItems.forEach((item) => {
      const itemRoute = item.getAttribute('routerLink');
      if (itemRoute && this.currentRoute.split('?')[0] === itemRoute) {
        const labelSpan = item.querySelector('.mdc-button__label > span');
        if (labelSpan) {
          labelSpan.classList.add('active');
        }
      } else {
        const labelSpan = item.querySelector('.mdc-button__label > span');
        if (labelSpan) {
          labelSpan.classList.remove('active');
        }
      }
    });
  }

  protected readonly Lang = Lang;
}
