@if (text) {
  <button
    class="a-tcmrActionButton__button py-2 px-6 rounded"
    [ngClass]="getClass()"
    (click)="onClick()"
    [disabled]="disabled"
  >
    {{ getText() | transloco }}
  </button>
} @else {
  @if (this.plain) {
    <button class="" (click)="onClick()" [disabled]="disabled">
      <mat-icon>{{ icon }}</mat-icon>
    </button>
  } @else {
    <button
      class="a-tcmrActionButton__button pt-2 pb-px px-6 rounded"
      [ngClass]="getClass()"
      [disabled]="disabled"
      (click)="onClick()"
    >
      <mat-icon>{{ icon }}</mat-icon>
    </button>
  }
}
