import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { UserRoleService } from 'services/api/user-role.service';
import {
  saveUserRoles,
  updateSelectedUserRoleByOrganizationId,
  saveUserLanguage,
} from './user-info/user-info.actions';
import { Lang, LANG_MAP } from '../../core/transloco/transloco-root.module';

@Injectable({ providedIn: 'root' })
export class StoreService {
  constructor(
    private userRoleService: UserRoleService,
    private store: Store,
    private authService: AuthService,
  ) {}

  refreshOrganizationStore() {
    this.userRoleService.getUserRoles().subscribe((userUserRoles) => {
      if (userUserRoles.length) {
        this.store.dispatch(
          saveUserRoles({
            userRoles: userUserRoles,
          }),
        );

        this.authService.user$.subscribe((user) => {
          const userMainOrg =
            user?.['https://mazdaeur.com/roles/user']?.['employerDomain']?.ref;

          const role =
            userUserRoles.find(
              (role) => role.organization?.code === userMainOrg,
            ) ?? userUserRoles[0];

          if (role) {
            this.store.dispatch(
              updateSelectedUserRoleByOrganizationId({
                organizationId: role.organizationId,
              }),
            );
          }

          const userLanguageCode =
            user?.['https://mazdaeur.com/roles/user']?.['user_metadata'][
              'language'
            ];

          if (userLanguageCode) {
            const userLang: Lang =
              LANG_MAP[userLanguageCode as keyof typeof LANG_MAP] ?? Lang.EN_GB;

            this.store.dispatch(saveUserLanguage({ language: userLang }));
          }

          // TODO: request user's rolePrivileges
          // TODO: create userRolePrivileges in store
        });
      }
    });
  }
}
