import {
  provideTransloco,
  TranslocoModule,
  TranslocoService,
} from '@ngneat/transloco';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-loader';
import { environment } from '../../../environments/environment';
import {
  provideTranslocoLocale,
  TranslocoLocaleModule,
} from '@ngneat/transloco-locale';
import { firstValueFrom } from 'rxjs';

export enum Lang {
  EN_GB = 'en-GB',
  DE_DE = 'de-DE',
  ES_ES = 'es-ES',
  FR_FR = 'fr-FR',
  NL_NL = 'nl-NL',
}

export const LANG_MAP = {
  en: Lang.EN_GB,
  de: Lang.DE_DE,
  es: Lang.ES_ES,
  fr: Lang.FR_FR,
  nl: Lang.NL_NL,
  'en-GB': Lang.EN_GB,
  'de-DE': Lang.DE_DE,
  'es-ES': Lang.ES_ES,
  'fr-FR': Lang.FR_FR,
  'nl-NL': Lang.NL_NL,
};

const DEFAULT_LANGAGE = Lang.EN_GB;
export function preloadTranslation(transloco: TranslocoService) {
  return function () {
    transloco.setActiveLang(DEFAULT_LANGAGE);
    return firstValueFrom(transloco.load(DEFAULT_LANGAGE));
  };
}

@NgModule({
  exports: [TranslocoModule, TranslocoLocaleModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: Object.values(Lang),
        defaultLang: Lang.EN_GB,
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoLocale({ defaultLocale: Lang.EN_GB }),
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslocoService],
      useFactory: preloadTranslation,
    },
  ],
})
export class TranslocoRootModule {}
