import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { MessageModalService } from './modal/message-modal.service';
import { SnackbarService } from './snackbar/snackbar.service';
import { OperationStatus } from '../shared/components/operation-snackbar/operation-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private messageModalService: MessageModalService,
    private snackbarService: SnackbarService,
  ) {}

  handleError = (error: HttpErrorResponse) => {
    this.snackbarService.open(
      `COMMON.SERVER_ERROR`,
      OperationStatus.ERROR,
      error.error.message,
    );
    return throwError(
      () => new Error('Something bad happened; please try again later.'),
    );
  };
}
