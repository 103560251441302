import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { MainPageComponent } from './features/main-page/main-page.component';
import { NotFoundComponent } from './layouts/not-found/not-found.component';
import { MainAppLayoutComponent } from './layouts/main/main-app-layout.component';
import { DefaultLayoutComponent } from './layouts/default/default-layout.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'organizations', // TODO: do not forget to remove me
        title: 'Organizations - TCMR3',
        loadChildren: () =>
          import(
            './features/organizations-generic/organizations-generic.module'
          ).then((m) => m.OrganizationsGenericModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'banks-features',
        title: 'Bank Features - TCMR3',
        loadChildren: () =>
          import('./features/banks-features/bank-features.module').then(
            (m) => m.BankFeaturesModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'dealer-admin',
        title: 'Dealer Admin - TCMR3',
        loadChildren: () =>
          import('./features/dealer-admin/dealer-admin.module').then(
            (m) => m.DealerAdminModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'vehicle-admin',
        title: 'Vehicle Admin - TCMR3',
        loadChildren: () =>
          import('./features/vehicle-admin/vehicle-admin.module').then(
            (m) => m.VehicleAdminModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'deal',
        title: 'Deal Admin - TCMR3',
        loadChildren: () =>
          import('./features/deal/deal.module').then((m) => m.DealModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'bank-admin',
        title: 'Bank Admin - TCMR3',
        loadChildren: () =>
          import('./features/bank-admin/bank-admin.module').then(
            (m) => m.BankAdminModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'activity-admin',
        title: 'Activities Admin - TCMR3',
        loadChildren: () =>
          import('./features/activity-admin/activity-admin.module').then(
            (m) => m.ActivityAdminModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'settings',
        title: 'Settings',
        loadChildren: () =>
          import('./features/settings/settings.module').then(
            (m) => m.SettingsModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'iframes',
        title: 'IFrames - TCMR3',
        loadChildren: () =>
          import('./features/iframes/iframes.module').then(
            (m) => m.IframesModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        component: MainAppLayoutComponent,
        children: [
          {
            path: '',
            component: MainPageComponent,
          },
        ],
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
