<div matSnackBarLabel class="py-4 flex items-center justify-center gap-2">
  @switch (operationInfo().status) {
    @case (OperationStatus.SUCCESS) {
      <mat-icon matSnackBarAction class="text-green-700">check_circle</mat-icon>
      <span
        >{{ operationInfo().message | transloco }}
        @if (!!operationInfo().url) {
          <a class="text-blue-500" href="{{ operationInfo().url }}"
            >{{ 'COMMON.SETTINGS_FILES' | transloco }}
          </a>
        }
      </span>
    }
    @case (OperationStatus.ERROR) {
      <mat-icon class="text-red-500">error</mat-icon>

      @if (isArray($any(operationInfo()).reason)) {
        <ul>
          @for (item of $any(operationInfo()).reason; track item) {
            <li>{{ item }}</li>
          }
        </ul>
      } @else {
        <span>{{ operationInfo().message | transloco }} </span>
        {{ $any(operationInfo()).reason }}
      }
    }
  }
</div>
