import { createReducer, on } from '@ngrx/store';
import {
  updateSelectedUserRoleByOrganizationId,
  saveSelectedUserRole,
  saveUserRoles,
  saveUserLanguage,
} from './user-info.actions';
import { UserInfoStore, initialState } from './user-info.state';

export const userInfoReducer = createReducer(
  initialState,
  on(
    updateSelectedUserRoleByOrganizationId,
    (state, { organizationId }): UserInfoStore => {
      return {
        ...state,
        selectedUserRole: state.userRoles.find(
          (userRole) => userRole.organizationId === organizationId,
        ),
      };
    },
  ),
  on(
    saveSelectedUserRole,
    (state, { userRole }): UserInfoStore => ({
      ...state,
      selectedUserRole: userRole,
    }),
  ),
  on(
    saveUserRoles,
    (state, { userRoles }): UserInfoStore => ({
      ...state,
      userRoles: userRoles,
    }),
  ),
  on(
    saveUserLanguage,
    (state, { language }): UserInfoStore => ({
      ...state,
      userLanguage: language,
    }),
  ),
);

export const userInfoReducers = {
  userInfo: userInfoReducer,
};
