import { Component, Inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { AsyncPipe, DOCUMENT, NgIf, NgOptimizedImage } from '@angular/common';
import { ButtonVariant } from '../../shared/components/button-generator/button-generator.component';
import { ButtonGeneratorModuleModule } from '../../shared/components/button-generator/button-generator-module.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: 'app-auth-button',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    ButtonGeneratorModuleModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    RouterLink,
    TranslocoPipe,
    NgOptimizedImage,
  ],
  templateUrl: './auth-button.component.html',
  styleUrl: './auth-button.component.css',
})
export class AuthButtonComponent {
  constructor(
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService,
  ) {
    this.auth.getAccessTokenSilently();
  }

  login(): void {
    this.auth.loginWithRedirect({ appState: { target: '/' } });
  }

  logout(): void {
    this.auth.logout({ logoutParams: { returnTo: document.location.origin } });
  }

  protected readonly ButtonVariant = ButtonVariant;
}
