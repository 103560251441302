import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonGeneratorComponent } from './button-generator.component';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ButtonGeneratorComponent],
  imports: [CommonModule, TranslocoPipe, MatButtonModule, MatIconModule],
  exports: [ButtonGeneratorComponent],
})
export class ButtonGeneratorModuleModule {}
