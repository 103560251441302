import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageType } from '../../models/Message';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.css'],
})
export class MessageModalComponent {
  messageType!: MessageType;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { type: MessageType; message: string },
  ) {
    this.messageType = data.type;
  }

  isInfo(): boolean {
    return true;
  }

  isError(): boolean {
    return false;
  }

  protected readonly MessageType = MessageType;
}
