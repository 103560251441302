<ng-container *ngIf="selectedOrganization$ | async as selectedOrg">
  <select
    id="organization-selector"
    class="bg-black text-white text-xs font-display"
    (ngModelChange)="changeOrganization($any($event))"
    [ngModel]="selectedOrg.id"
  >
    <option *ngFor="let org of userOrganizations$ | async" [ngValue]="org.id">
      {{ org.name }}
    </option>
  </select>
</ng-container>
