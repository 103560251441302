import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap, take } from 'rxjs';
import { selectSelectedOrganization } from 'shared/store/user-info/user-info.selector';

@Injectable()
export class SelectedOrganizationInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.store.select(selectSelectedOrganization).pipe(
      take(1),
      switchMap((selectedOrg) => {
        if (selectedOrg) {
          const modifiedRequest = request.clone({
            setHeaders: { userOrgId: selectedOrg.id },
          });
          return next.handle(modifiedRequest);
        } else {
          return next.handle(request);
        }
      }),
    );
  }
}
