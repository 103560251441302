import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageModalComponent } from '../../shared/components/message-modal/message-modal.component';
import { MessageType } from '../../shared/models/Message';

@Injectable({
  providedIn: 'root',
})
export class MessageModalService {
  constructor(private dialog: MatDialog) {}

  openModal(type: MessageType, message: string): void {
    this.dialog.open(MessageModalComponent, {
      width: '400px',
      data: { type, message },
    });
  }
}
