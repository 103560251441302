import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { Store } from '@ngrx/store';
import { StoreService } from 'shared/store/store.service';
import { selectUserLanguage } from 'shared/store/user-info/user-info.selector';
import { Lang } from '../../core/transloco/transloco-root.module';
import { AppState, AuthService } from '@auth0/auth0-angular';
import { LoginService } from 'services/login.service';
import { saveUserLanguage } from 'shared/store/user-info/user-info.actions';

@Component({
  selector: 'app-default',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css'],
})
export class DefaultLayoutComponent implements OnInit {
  languages = [] as AvailableLangs;

  userLanguage$ = this.store.select(selectUserLanguage);

  constructor(
    private translationService: TranslocoService,
    private localeService: TranslocoLocaleService,
    public router: Router,
    public route: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private storeService: StoreService,
    private store: Store,
    private auth: AuthService,
    private loginService: LoginService,
  ) {}

  switchLanguage(value: Lang) {
    this.translationService.setActiveLang(value);
    this.localeService.setLocale(value);
    this.dateAdapter.setLocale(value);
    this.store.dispatch(saveUserLanguage({ language: value }));
  }

  ngOnInit(): void {
    this.languages = this.translationService.getAvailableLangs();

    // Get user language from store if available, otherwise use default
    this.userLanguage$.subscribe((language) => {
      this.switchLanguage(language ?? Lang.EN_GB);
    });

    // refresh user role/org store data
    this.auth.isAuthenticated$.subscribe((value: boolean) => {
      if (value) {
        this.storeService.refreshOrganizationStore();
      }
    });

    // handle redirection after login
    this.auth.appState$.subscribe((state: AppState) => {
      this.loginService.refreshUserData();
      this.router.navigate([state.target]);
    });
  }
}
