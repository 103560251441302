import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRoutes } from 'core/enums/api.routes';
import { Observable, catchError } from 'rxjs';
import { ErrorHandlerService } from 'services/error-handler.service';
import {
  UserRole,
  UserRoleFilters,
  UserRoleInfo,
} from 'shared/models/UserRole';
import { environment } from '../../../environments/environment';
import { SuccessApiDto } from './types/success-api.dto';

@Injectable({ providedIn: 'root' })
export class UserRoleService {
  constructor(
    protected http: HttpClient,
    protected errorHandlerService: ErrorHandlerService,
  ) {}

  TCMR_API = environment.apiUrl;

  filterUserRoles(
    { page, limit }: { page?: number; limit?: number } = {},
    filters?: UserRoleFilters,
  ): Observable<SuccessApiDto<UserRole[]>> {
    return this.http
      .post<
        SuccessApiDto<UserRole[]>
      >(ApiRoutes.POST_FILTER_USER_ROLES, filters, { params: page && limit ? { page, limit } : {} })
      .pipe(catchError(this.errorHandlerService.handleError));
  }

  getUserRoles() {
    return this.http
      .get<UserRoleInfo[]>(ApiRoutes.GET_USER_ROLES)
      .pipe(catchError(this.errorHandlerService.handleError));
  }
}
