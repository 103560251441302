import { createReducer, on } from '@ngrx/store';
import { CurrencyStore, initialState } from './currency.state';
import { saveCurrency } from './currency.actions';

export const currencyReducer = createReducer(
  initialState,
  on(
    saveCurrency,
    (state, { currency }): CurrencyStore => ({ ...state, currency }),
  ),
);

export const currencyReducers = {
  currency: currencyReducer,
};
